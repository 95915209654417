<template>
    <div>
        <div class="create-company-container w-100 mt-6 pb-5">
            <div class="create-company-header d-flex align-items-center justify-content-between">
                <span class="fs-14 pl-4 text-white">Board Member Information</span>
                <span class="pointer" @click="expandTabs">
                    <i class="text-white" :class="{'icon-chevron-double-up':consultant_expand, 'icon-chevron-double-down':!consultant_expand}" style="font-size: 20px;"></i>
                </span>
            </div>
            <div v-if="consultant_expand" class="px-6">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <div class="w-100 position-relative">
                            <span class="input-label">Personal Email ID<span class="pl-1 text-danger">*</span></span>
                            <div class="d-flex align-items-center mt-2">
                                <input type="text" maxlength="100" class="input-field" v-validate="'required|email'"
                                    v-model="consultant_info.personal_email_id" placeholder="Enter Personal Email ID"
                                    name="personal_email_id" />
                            </div>
                            <div class="text-right position-absolute" style="right: 0;">
                                <span
                                    class="input-field-length">{{ consultant_info.personal_email_id.length }}/100</span>
                            </div>
                            <span class="invalid-feedback-form text-danger" v-show="errors.has('personal_email_id')">
                                {{errors.first('personal_email_id')}}
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <div class="w-100 position-relative">
                            <span class="input-label">Login Email ID<span class="pl-1 text-danger">*</span></span>
                            <div class="d-flex align-items-center mt-2">
                                <div class="position-relative w-100">
                                    <div class="d-flex align-items-center">
                                        <input type="text" maxlength="20" :disabled="$route.path.startsWith('/board/editboard/cmpid')" class="input-field" v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                            v-model="consultant_info.login_email_id" placeholder="Enter Login Email ID"
                                            name="login_email_id" @change="checkEmail" />
                                    </div>
                                    <div class="text-right position-absolute" style="right: 0;">
                                        <span class="input-field-length">{{ consultant_info.login_email_id.length }}/20</span>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mx-2">
                                    <span>@</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" :disabled="true" :readonly="true" class="input-field"
                                        v-model="consultant_info.company_domain" placeholder="Enter"
                                    />
                                </div>
                            </div>
                            <span v-if="check_email.status_id == 0" class="invalid-feedback-form text-danger">
                                Login id already Exist
                            </span>
                            <span v-else class="invalid-feedback-form text-danger" v-show="errors.has('login_email_id')">
                                {{errors.first('login_email_id')}}
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <div class="w-100 position-relative">
                            <span class="input-label">Board Member First Name<span class="pl-1 text-danger">*</span></span>
                            <div class="d-flex align-items-center mt-2">
                                <input type="text" maxlength="45" class="input-field" v-validate="'required'"
                                    v-model="consultant_info.consultant_first_name" placeholder="Enter Board Member First Name"
                                    name="first_name" />
                            </div>
                            <div class="text-right position-absolute" style="right: 0;">
                                <span
                                    class="input-field-length">{{ consultant_info.consultant_first_name.length }}/45
                                </span>
                            </div>
                            <span class="invalid-feedback-form text-danger" v-show="errors.has('first_name')">
                                {{errors.first('first_name')}}
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <div class="w-100 position-relative">
                            <span class="input-label">Board Member Last Name<span class="pl-1 text-danger">*</span></span>
                            <div class="d-flex align-items-center mt-2">
                                <input type="text" maxlength="45" class="input-field" v-validate="'required'"
                                    v-model="consultant_info.consultant_last_name" placeholder="Enter Board Member Last Name"
                                    name="last_name" />
                            </div>
                            <div class="text-right position-absolute" style="right: 0;">
                                <span
                                    class="input-field-length">{{ consultant_info.consultant_last_name.length }}/45
                                </span>
                            </div>
                            <span class="invalid-feedback-form text-danger" v-show="errors.has('last_name')">
                                {{errors.first('last_name')}}
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-6">
                                <span class="input-label">Start Date</span>
                                <div class="d-flex align-items-center mt-2">
                                    <date-picker class="profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0" 
                                    v-model="consultant_info.start_date" value-type="format" 
                                    :not-before="new Date()"
                                    :clearable="false"
                                    :editable="false" style="padding-left: 0px !important;"
                                    lang="en" type="date" format="DD/MM/YYYY"></date-picker>
                                    <!-- <i class="icon icon-tick-success icon-check-circle"></i> -->
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 pl-5">
                                <span class="input-label">Time</span>
                                <div class="d-flex align-items-center mt-2">
                                    <date-picker v-model="consultant_info.start_time" style="padding-right: 3px !important;padding-left: 0px !important;" :minute-step="1" name="start_time" class="profile-date-picker calendar-edit-per input-field calendar-down-arrow-grey" format="hh:mm A"   type="time" placeholder="HH:MM" value-type="format" lang="en"  :clearable="false" :editable="false" confirm confirm-text="APPLY"></date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <!-- <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-6">
                                <span class="input-label">End Date</span>
                                <div class="d-flex align-items-center mt-2">
                                    <date-picker class="profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0" 
                                    v-model="consultant_info.end_date" value-type="format" lang="en"
                                    :not-before="consultant_info.start_date ? consultant_info.start_date : new Date()"
                                    :clearable="false"
                                    :editable="false" style="padding-left: 0px !important;"
                                    type="date" format="DD/MM/YYYY"></date-picker>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 pl-5">
                                <span class="input-label">Time</span>
                                <div class="d-flex align-items-center mt-2">
                                    <date-picker v-model="consultant_info.end_time" style="padding-right: 3px !important;padding-left: 0px !important;" name="end_time" class="profile-date-picker calendar-edit-per input-field calendar-down-arrow-grey" format="hh:mm" type="time" placeholder="HH:MM" value-type="format" lang="en"  :clearable="false" :editable="false" confirm confirm-text="APPLY"></date-picker>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                        <div class="w-100 position-relative">
                            <span class="input-label">Role</span>
                            <div class="d-flex align-items-center mt-2">
                                <input type="text" :readonly="true" :disabled="true" maxlength="100" class="input-field" v-validate="'required'"
                                    v-model="consultant_info.role" placeholder="Enter Role" style="text-transform: capitalize;"
                                    name="First-Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-10 mb-10">
                <div class="d-flex align-items-center justify-content-center">
                     <button class="btn btn-outline-secondary" :disabled="save_create_btn_hide" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                     <button class="btn btn-new-primary ml-5" :disabled="!consultant_expand || save_create_btn_hide || fields_not_changed || check_email.status_id == 0" @click="nextPermissionProduct" style="width: 120px;border-radius: 5px;">Next
                        <hollow-dots-spinner v-if="save_create_btn_hide" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                    </button>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import consultant from '../mixins/consultant';
    import { SweetModal } from 'sweet-modal-vue';
    export default {
        mixins: [consultant],
        props: ['consultant_data'],
        components: {
            SweetModal
        },
        data() {
            return {
                warning_msg: '',
                success_msg: '',
                consultant_info: {
                    personal_email_id: '',
                    login_email_id: '',
                    consultant_first_name:'',
                    consultant_last_name:'',
                    role: 'As Board Member',
                    company_domain: '',
                    start_date: '',
                    end_date: '',
                    start_time: '',
                    end_time: '',
                },
                consultant_expand: true,
                save_create_btn_hide:false,
                check_email: [],
                stringified_response: "",
            }
        },
        methods: {
            async checkEmail() {
                let params = {
                    "login_email": this.consultant_info.login_email_id+'@'+this.consultant_info.company_domain.replace(/.+\/\/|www.|\+/g, '')
                };
                this.check_email = await this.checkEmailExit(params);
            },
            expandTabs() {
                this.consultant_expand = !this.consultant_expand;
            },
            nextPermissionProduct() {
                this.save_create_btn_hide = true
                this.$validator.validateAll().then(result => {
                    if (result) {
                        localStorage.setItem("consultant_data", JSON.stringify(this.create_data));
                        this.$emit('switchWizard');
                    }
                })
                this.save_create_btn_hide = false
            },
            cancel() {
                this.$router.push('/board/listview/cmpid/'+this.$route.params.company_id);
            },
            async getCompData(company_id) {
                try {
                    let response = await this.getCompanyById(company_id);
                    this.consultant_info.company_domain = response.response.domain;
                }
                catch (err) {
                    // console.log(err);
                }
            },
        },
        mounted() {
            // this.checkEmail();
            if(this.$route.path.startsWith('/board/editboard/cmpid')) {
                this.consultant_info.personal_email_id = this.consultant_data.personal_email;
                this.consultant_info.login_email_id = this.consultant_data.login_email.split("@")[0];
                this.consultant_info.consultant_first_name = this.consultant_data.first_name;
                this.consultant_info.consultant_last_name = this.consultant_data.last_name;
                this.consultant_info.role = 'As Board Member';
                this.consultant_info.company_domain = this.consultant_data.login_email.split("@")[1];
                this.consultant_info.start_date =  this.consultant_data.start_date ? this.consultant_data.start_date.split("-")[2]+"/"+this.consultant_data.start_date.split("-")[1]+"/"+this.consultant_data.start_date.split("-")[0] : "";
                this.consultant_info.end_date = this.consultant_data.end_date ? this.consultant_data.end_date.split("-")[2]+"/"+this.consultant_data.end_date.split("-")[1]+"/"+this.consultant_data.end_date.split("-")[0] : "";
                this.consultant_info.start_time = this.consultant_data.start_time ? this.consultant_data.start_time.split(":")[0]+':'+this.consultant_data.start_time.split(":")[1] : "";
                this.consultant_info.end_time = this.consultant_data.end_time ? this.consultant_data.end_time.split(":")[0]+':'+this.consultant_data.end_time.split(":")[1] : "";
                this.stringified_response = JSON.stringify(this.create_data)
            } else {
                this.getCompData(this.$route.params.company_id);
            }
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            create_data() {
                return {
                    personal_email: this.consultant_info.personal_email_id,
                    login_email: this.consultant_info.login_email_id+'@'+this.consultant_info.company_domain.replace(/.+\/\/|www.|\+/g, ''),
                    first_name: this.consultant_info.consultant_first_name,
                    last_name: this.consultant_info.consultant_last_name,
                    start_date: this.consultant_info.start_date ? this.consultant_info.start_date.split('/')[2]+'-'+this.consultant_info.start_date.split('/')[1]+'-'+this.consultant_info.start_date.split('/')[0] : '',
                    start_time: this.consultant_info.start_time,
                    end_date: this.consultant_info.end_date ? this.consultant_info.end_date.split('/')[2]+'-'+this.consultant_info.end_date.split('/')[1]+'-'+this.consultant_info.end_date.split('/')[0] : '',
                    end_time: this.consultant_info.end_time,
                    company_id: this.$route.params.company_id,
                    role: 'board_member',
                    products: []
                }
            }
        },
        created() {
            const dict = {
                custom: {
                    first_name: {
                        required: () => "First Name is required",
                        regex: () => "Enter a Valid Name",
                    },
                    last_name: {
                        required: () => "Last Name is required",
                        regex: () => "Enter a Valid Name",
                    },
                    personal_email_id: {
                        required: () => "Personal Email ID is required",
                        email: () => "Enter a Valid Email ID",
                    },
                    login_email_id:{
                        required: () => "Login Email ID is required",
                        regex: () => "Please enter valid Email ID",
                    },
                }
            };
            this.$validator.localize("en", dict);       
        },
    }
</script>

<style scoped>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .icon-tick-warning{
        color: #ff9a06
    }
    .icon-tick-success{
        color: #008a63;
    }
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
    }

    .create-company-container:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .create-company-container:nth-child(2) {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .create-company-container:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .overflow-auto {
        overflow: auto;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .input-field {
        border-radius: 8px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100%;
        height: 40px;
    }

    .input-field-length {
        opacity: 0.5;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: #000;
    }

    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }
    .profile-date-picker {
        padding: 5px 10px !important;
    }
    .btn-new-primary:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>